/* Navbar başlangıçta 40px top ile başlasın */
.navbar {
  background: linear-gradient(to right, rgba(13, 31, 33, 0.98), rgba(13, 31, 33, 0.95)) !important;
  padding: 1rem 0;
  position: fixed;
  width: 100%;
  transition: all 0.4s ease;
  z-index: 1000;
  backdrop-filter: blur(10px);
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

/* Scroll yapıldıktan sonra top değeri 0 olsun */
.navbar-scrolled {
  padding: 0.7rem 0;
  background: linear-gradient(to right, rgba(13, 31, 33, 0.98), rgba(13, 31, 33, 0.98)) !important;
  box-shadow: 0 2px 20px rgba(0, 0, 0, 0.1);
}

/* Üst Bilgi ile Ana Menü Arasındaki Boşluk */
.menu-spacing {
  border-bottom: 1px solid #ccc;
}

/* Sosyal medya ikonları ile adresin hizalanması */
.top-bar {
  display: flex;
  justify-content: space-between;
  background-color: #0d1f21 !important;
}

/* Menüdeki linklerin rengi ve ayarları */
.nav-link {
  color: rgba(255, 255, 255, 0.9) !important;
  font-weight: 500;
  padding: 0.5rem 1.2rem !important;
  margin: 0 0.3rem;
  transition: all 0.3s ease;
  position: relative;
  font-size: 1.1rem;
}

/* Alt çizginin hover ile animasyonlu bir şekilde görünmesi */
.nav-link::before {
  content: '';
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 0;
  height: 2px;
  background: linear-gradient(to right, #d4af37, #f8d77f);
  transition: all 0.3s ease;
  transform: translateX(-50%);
  opacity: 0;
}

/* Hover durumunda soldan sağa doğru çizgi görünür */
.nav-link:hover::before,
.nav-link.active::before {
  width: 70%;
  opacity: 1;
}

.nav-link:hover,
.nav-link.active {
  color: #d4af37 !important;
  transform: translateY(-2px);
}

/* Navbar başlık fontu ve rengi */
.navbar-brand {
  position: relative;
  padding: 0;
}

.navbar-brand img {
  transition: all 0.3s ease;
}

.navbar-scrolled .navbar-brand img {
  transform: scale(0.95);
}

/* Çağrı butonunun sağda durmasını sağlayan CSS */
.call-btn {
  background: linear-gradient(45deg, #d4af37, #f8d77f) !important;
  border: none !important;
  padding: 0.6rem 1.5rem !important;
  border-radius: 30px !important;
  font-weight: 600 !important;
  letter-spacing: 0.5px;
  transition: all 0.3s ease !important;
  box-shadow: 0 4px 15px rgba(212, 175, 55, 0.2);
}

.call-btn:hover {
  transform: translateY(-2px);
  box-shadow: 0 6px 20px rgba(212, 175, 55, 0.3) !important;
  background: linear-gradient(45deg, #f8d77f, #d4af37) !important;
}

.call-btn a {
  display: flex;
  align-items: center;
  gap: 8px;
}

@media screen and (max-width: 768px) {
  .top-bar .top-bar-number {
    display: none !important;
  }
}

.custom-navbar {
  padding: 1rem 0;
  transition: all 0.3s ease;
  background-color: transparent;
}

.custom-navbar.scrolled {
  background-color: rgba(255, 255, 255, 0.97);
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  padding: 0.5rem 0;
}

.navbar-logo {
  height: 50px;
  transition: all 0.3s ease;
}

.scrolled .navbar-logo {
  height: 45px;
}

.navbar-toggler {
  border: none;
  padding: 0.5rem;
  transition: all 0.3s ease;
  position: relative;
  z-index: 2;
}

.navbar-toggler:focus {
  box-shadow: none;
  outline: none;
}

.navbar-toggler-icon {
  background-image: none;
  position: relative;
  transition: all 0.3s ease;
}

.navbar-toggler-icon::before,
.navbar-toggler-icon::after {
  content: '';
  position: absolute;
  width: 24px;
  height: 2px;
  background-color: #fff;
  transition: all 0.3s ease;
}

.navbar-toggler-icon::before {
  top: 8px;
}

.navbar-toggler-icon::after {
  bottom: 8px;
}

.navbar-toggler[aria-expanded="true"] .navbar-toggler-icon::before {
  transform: rotate(45deg) translate(5px, 5px);
}

.navbar-toggler[aria-expanded="true"] .navbar-toggler-icon::after {
  transform: rotate(-45deg) translate(5px, -5px);
}

/* Mobil menü stilleri */
@media (max-width: 991px) {
  .navbar-collapse {
    background: rgba(13, 31, 33, 0.98);
    padding: 1rem;
    border-radius: 10px;
    margin-top: 1rem;
    box-shadow: 0 4px 25px rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(10px);
    border: 1px solid rgba(255, 255, 255, 0.1);
  }

  .nav-link {
    padding: 1rem !important;
    margin: 0.3rem 0;
    border-radius: 5px;
    text-align: center;
  }

  .nav-link:hover,
  .nav-link.active {
    background: rgba(212, 175, 55, 0.1);
  }

  .call-btn {
    margin: 1rem 0;
    width: 100%;
    text-align: center;
    justify-content: center;
  }
}

/* Animasyonlar */
@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.navbar-nav {
  animation: fadeInDown 0.5s ease;
}

/* Responsive Ayarlar */
@media (max-width: 768px) {
  .navbar {
    padding: 0.8rem 0;
  }

  .navbar-brand img {
    max-width: 130px;
  }

  .nav-link {
    font-size: 1rem;
  }
}

@media (max-width: 576px) {
  .navbar {
    padding: 0.6rem 0;
  }

  .navbar-brand img {
    max-width: 120px;
  }
}

