.page-wrapper {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

/* Hero Section */
.services-hero {
  position: relative;
  height: 500px;
  background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url('../assets/banners/terazi.png');
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  margin-bottom: 0;
  position: relative;
  overflow: hidden;
}

.services-hero::before {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100px;
  background: linear-gradient(to top, #fff, transparent);
}

.hero-content {
  position: relative;
  z-index: 2;
  text-align: center;
  max-width: 800px;
  margin: 0 auto;
  padding: 0 20px;
}

.hero-title {
  font-size: 4rem;
  font-weight: 800;
  margin-bottom: 20px;
  text-transform: uppercase;
  letter-spacing: 3px;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
  animation: fadeInDown 1s ease;
}

.hero-subtitle {
  font-size: 1.8rem;
  font-weight: 300;
  margin-top: 20px;
  color: rgba(255, 255, 255, 0.9);
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
  animation: fadeInUp 1s ease;
}

.hero-divider {
  width: 100px;
  height: 4px;
  background: linear-gradient(to right, transparent, #d4af37, transparent);
  margin: 30px auto;
  animation: widthGrow 1.5s ease;
}

/* Section Styles */
section {
  padding: 100px 0;
  position: relative;
}

.section-header {
  margin-bottom: 70px;
  text-align: center;
}

.section-title {
  color: #333;
  font-size: 3rem;
  font-weight: 700;
  margin-bottom: 20px;
  position: relative;
  display: inline-block;
}

.section-divider {
  width: 80px;
  height: 3px;
  background: linear-gradient(to right, transparent, #d4af37, transparent);
  margin: 20px auto;
}

/* Main Services */
.main-services {
  background-color: #fff;
  position: relative;
}

.service-card {
  margin-bottom: 40px;
  background: #fff;
  border-radius: 20px;
  overflow: hidden;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.08);
  transition: all 0.4s ease;
  height: 100%;
}

.service-card:hover {
  transform: translateY(-15px);
  box-shadow: 0 15px 40px rgba(0, 0, 0, 0.15);
}

.service-image {
  position: relative;
  height: 300px;
  overflow: hidden;
}

.service-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.5s ease;
}

.service-card:hover .service-image img {
  transform: scale(1.1);
}

.service-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(to bottom, transparent, rgba(0, 0, 0, 0.7));
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity 0.4s ease;
}

.service-card:hover .service-overlay {
  opacity: 1;
}

.service-icon {
  font-size: 4rem;
  color: #d4af37;
  filter: drop-shadow(2px 2px 4px rgba(0, 0, 0, 0.3));
  transform: translateY(20px);
  transition: all 0.4s ease;
}

.service-card:hover .service-icon {
  transform: translateY(0);
}

.service-content {
  padding: 35px;
  background: #fff;
  position: relative;
}

.service-content h3 {
  color: #333;
  font-size: 1.8rem;
  font-weight: 700;
  margin-bottom: 20px;
  position: relative;
  padding-bottom: 15px;
}

.service-content h3::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 50px;
  height: 2px;
  background: #d4af37;
  transition: width 0.3s ease;
}

.service-card:hover .service-content h3::after {
  width: 100px;
}

.service-content p {
  color: #666;
  font-size: 1.1rem;
  line-height: 1.8;
  margin: 0;
}

/* Practice Areas */
.practice-areas {
  background: linear-gradient(135deg, #f8f9fa 0%, #fff 100%);
  position: relative;
}

.practice-areas::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 1px;
  background: linear-gradient(to right, transparent, rgba(212, 175, 55, 0.3), transparent);
}

.practice-card {
  background: #fff;
  padding: 40px 30px;
  border-radius: 20px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.05);
  text-align: center;
  height: 100%;
  transition: all 0.4s ease;
  margin-bottom: 30px;
  position: relative;
  overflow: hidden;
}

.practice-card::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 4px;
  background: linear-gradient(to right, #d4af37, transparent);
  transform: translateX(-100%);
  transition: transform 0.4s ease;
}

.practice-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 15px 40px rgba(0, 0, 0, 0.1);
}

.practice-card:hover::before {
  transform: translateX(0);
}

.practice-icon {
  font-size: 3rem;
  color: #d4af37;
  margin-bottom: 25px;
  transition: transform 0.4s ease;
}

.practice-card:hover .practice-icon {
  transform: scale(1.1) rotate(5deg);
}

.practice-card h3 {
  color: #333;
  font-size: 1.5rem;
  font-weight: 700;
  margin-bottom: 20px;
  transition: color 0.3s ease;
}

.practice-card:hover h3 {
  color: #d4af37;
}

.practice-card p {
  color: #666;
  font-size: 1.1rem;
  line-height: 1.7;
  margin: 0;
}

/* Animations */
@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translateY(-30px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(30px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes widthGrow {
  from {
    width: 0;
  }
  to {
    width: 100px;
  }
}

/* Responsive Styles */
@media (max-width: 992px) {
  .hero-title {
    font-size: 3.5rem;
  }

  .hero-subtitle {
    font-size: 1.5rem;
  }

  .section-title {
    font-size: 2.5rem;
  }

  .service-image {
    height: 250px;
  }
}

@media (max-width: 768px) {
  .services-hero {
    height: 400px;
  }

  .hero-title {
    font-size: 2.8rem;
  }

  .hero-subtitle {
    font-size: 1.3rem;
  }

  section {
    padding: 60px 0;
  }

  .section-title {
    font-size: 2.2rem;
  }

  .service-card {
    margin-bottom: 30px;
  }

  .practice-card {
    padding: 30px 20px;
  }
}

@media (max-width: 576px) {
  .hero-title {
    font-size: 2.2rem;
  }

  .service-content {
    padding: 25px;
  }

  .practice-card {
    padding: 25px 20px;
  }
} 