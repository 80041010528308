/* CTA Section */
.cta-section {
    background-image: url('../assets/banners/banneralt.png'); /* Buraya gerçek arka plan resmini ekleyin */
    background-size: cover;
    background-position: center;
    padding: 80px 0;
    color: white;
    position: relative;
    z-index: 1;
  }
  
  /* CTA Başlık ve Alt Başlık */
  .cta-subtitle {
    font-size: 1rem;
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: 10px;
  }
  
  .cta-title {
    font-size: 2.5rem;
    font-weight: bold;
  }
  
  /* CTA Butonu */
  .cta-button {
    background-color: #f0ad4e;
    border: none;
    padding: 10px 30px;
    font-size: 1.1rem;
    color: white;
    transition: background-color 0.3s ease;
  }
  
  .cta-button:hover {
    background-color: #d9963b;
  }
  
  /* Responsive Tasarım */
  @media (max-width: 768px) {
    .cta-title {
      font-size: 2rem;
    }
  
    .cta-section {
      text-align: center;
    }
  
    .cta-button {
      margin-top: 20px;
    }
  }
  