/* Kartların aynı boyutta olmasını sağlayan stil */
.values-section-card {
  background-color: #f8f9fa;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  min-height: 250px; /* Sabit minimum yükseklik */
  display: flex;
  flex-direction: column;
  justify-content: space-between; /* İçeriğin düzgün hizalanmasını sağlar */
  text-align: center;
  transition: transform 0.3s ease;
}

.values-section-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.values-section-icon {
  margin-bottom: 15px;
  text-align: center;
}

.values-section-card-title {
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: 10px;
}

.values-section-card p {
  flex-grow: 1; /* Açıklamanın esneklik göstermesi için */
  margin-bottom: 10px;
}

/* Image responsive behavior */
.values-section-image {
  max-height: 400px;
  object-fit: cover;
  width: 100%;
}
