.page-wrapper {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

/* Hero Section */
.about-hero {
  position: relative;
  height: 500px;
  background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url('../assets/banners/terazi.png');
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  margin-bottom: 0;
  position: relative;
  overflow: hidden;
}

.about-hero::before {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100px;
  background: linear-gradient(to top, #fff, transparent);
}

.hero-content {
  position: relative;
  z-index: 2;
  text-align: center;
  max-width: 800px;
  margin: 0 auto;
  padding: 0 20px;
}

.hero-title {
  font-size: 4rem;
  font-weight: 800;
  margin-bottom: 20px;
  text-transform: uppercase;
  letter-spacing: 3px;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
  animation: fadeInDown 1s ease;
}

.hero-subtitle {
  font-size: 1.8rem;
  font-weight: 300;
  margin-top: 20px;
  color: rgba(255, 255, 255, 0.9);
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
  animation: fadeInUp 1s ease;
}

.hero-divider {
  width: 100px;
  height: 4px;
  background: linear-gradient(to right, transparent, #d4af37, transparent);
  margin: 30px auto;
  animation: widthGrow 1.5s ease;
}

/* Main Content Section */
.about-main-section {
  padding: 100px 0;
  background: #fff;
}

.about-content {
  padding-right: 50px;
}

.section-header {
  margin-bottom: 40px;
}

.section-title {
  color: #333;
  font-size: 2.5rem;
  font-weight: 700;
  margin-bottom: 20px;
  position: relative;
}

.section-divider {
  width: 60px;
  height: 3px;
  background: #d4af37;
  margin-bottom: 30px;
}

.section-divider.center {
  margin: 0 auto 30px;
}

.content-box {
  color: #666;
  font-size: 1.1rem;
  line-height: 1.8;
}

.content-box p {
  margin-bottom: 20px;
}

.about-image-wrapper {
  position: relative;
  padding: 20px;
}

.image-decoration {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: 15px;
  transform: translate(15px, 15px);
  z-index: 1;
}

.about-image {
  width: 100%;
  height: auto;
  border-radius: 15px;
  position: relative;
  z-index: 2;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.about-image:hover {
  transform: scale(1.02);
}

/* Values Section */
.values-section {
  padding: 100px 0;
  background: linear-gradient(135deg, #f8f9fa 0%, #fff 100%);
  position: relative;
}

.value-card {
  background: #fff;
  padding: 40px 30px;
  border-radius: 20px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.05);
  text-align: center;
  height: 100%;
  transition: all 0.4s ease;
  position: relative;
  overflow: hidden;
  margin-bottom: 30px;
}

.value-card::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 4px;
  background: linear-gradient(to right, #d4af37, transparent);
  transform: translateX(-100%);
  transition: transform 0.4s ease;
}

.value-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 15px 40px rgba(0, 0, 0, 0.1);
}

.value-card:hover::before {
  transform: translateX(0);
}

.icon-wrapper {
  width: 80px;
  height: 80px;
  background: #fff;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto 25px;
  position: relative;
  z-index: 1;
  transition: all 0.4s ease;
}

.icon-wrapper::before {
  content: '';
  position: absolute;
  top: -5px;
  left: -5px;
  right: -5px;
  bottom: -5px;
  background: linear-gradient(45deg, #d4af37, #f8d77f);
  border-radius: 50%;
  z-index: -1;
  opacity: 0.2;
  transition: all 0.4s ease;
}

.value-card:hover .icon-wrapper::before {
  opacity: 1;
  transform: scale(1.1);
}

.value-icon {
  font-size: 2.5rem;
  color: #d4af37;
  transition: all 0.4s ease;
}

.value-card:hover .value-icon {
  transform: scale(1.1) rotate(5deg);
}

.value-card h3 {
  color: #333;
  font-size: 1.5rem;
  font-weight: 700;
  margin-bottom: 15px;
  transition: color 0.3s ease;
}

.value-card:hover h3 {
  color: #d4af37;
}

.value-card p {
  color: #666;
  font-size: 1.1rem;
  line-height: 1.7;
  margin: 0;
}

/* History Section */
.history-section {
  padding: 100px 0;
  background: #fff;
  position: relative;
}

.history-content {
  max-width: 800px;
  margin: 0 auto;
  color: #666;
  font-size: 1.2rem;
  line-height: 1.8;
}

/* Contact Section */
.contact-section {
  padding: 100px 0;
  background: linear-gradient(135deg, #f8f9fa 0%, #fff 100%);
}

.contact-info-card {
  background: #fff;
  padding: 40px 30px;
  border-radius: 20px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.05);
  text-align: center;
  height: 100%;
  transition: all 0.4s ease;
  margin-bottom: 30px;
}

.contact-info-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 15px 40px rgba(0, 0, 0, 0.1);
}

.info-icon {
  font-size: 2.5rem;
  color: #d4af37;
  margin-bottom: 20px;
}

.contact-info-card h3 {
  color: #333;
  font-size: 1.5rem;
  font-weight: 700;
  margin-bottom: 15px;
}

.contact-info-card p {
  color: #666;
  font-size: 1.1rem;
  line-height: 1.7;
  margin: 0;
}

.contact-info-card a {
  color: #666;
  text-decoration: none;
  transition: color 0.3s ease;
}

.contact-info-card:hover a {
  color: #d4af37;
}

/* Animations */
@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translateY(-30px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(30px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes widthGrow {
  from {
    width: 0;
  }
  to {
    width: 100px;
  }
}

/* Responsive Styles */
@media (max-width: 992px) {
  .hero-title {
    font-size: 3.5rem;
  }

  .hero-subtitle {
    font-size: 1.5rem;
  }

  .about-content {
    padding-right: 0;
    margin-bottom: 50px;
  }

  .section-title {
    font-size: 2.2rem;
  }
}

@media (max-width: 768px) {
  .about-hero {
    height: 400px;
  }

  .hero-title {
    font-size: 2.8rem;
  }

  .hero-subtitle {
    font-size: 1.3rem;
  }

  .about-main-section,
  .values-section,
  .history-section,
  .contact-section {
    padding: 60px 0;
  }

  .image-decoration {
    transform: translate(10px, 10px);
  }
}

@media (max-width: 576px) {
  .hero-title {
    font-size: 2.2rem;
  }

  .section-title {
    font-size: 2rem;
  }

  .value-card,
  .contact-info-card {
    padding: 30px 20px;
  }
} 