/* Kartlara aynı yüksekliği vermek için */
.practice-card {
  background-color: #f8f9fa;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  min-height: 274px; /* Sabit bir minimum yükseklik */
  display: flex;
  flex-direction: column;
  justify-content: space-between; /* Kart içeriğinin düzgün hizalanmasını sağlar */
}

.practice-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

/* İkon stilini düzenlemek için */
.practice-icon {
  margin-bottom: 15px;
  text-align: center;
  color: #f0ad4e;
  font-size: 1.5rem;
}

.practice-category {
  font-size: 1.4rem;
}

.practice-title {
  font-size: 1.2rem;
  font-weight: bold;
}

.practice-card p {
  flex-grow: 1; /* Kartın açıklama kısmı büyüyebilir */
  margin-bottom: 10px;
}

/* Slick Slider Dots İçin Stiller */
.slick-dots {
  display: flex !important;
  justify-content: center;
  overflow: hidden;
}

.slick-dots li {
  display: none; /* Hepsini başlangıçta gizle */
}

/* İlk 6-7 dots'ı görünür yapalım */
.slick-dots li:nth-child(-n+7) {
  display: inline-block;
}