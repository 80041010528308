/* Carousel item tam ekran genişlik ve yükseklik */
.carousel-item {
  height: 100vh; /* Carousel'in yüksekliği tam ekran olacak */
  position: relative; /* Overlay için gerekli */
}

.carousel-item img {
  object-fit: cover; /* Görselin carousel alanını kaplaması sağlanır */
  width: 100%;
  height: 100%; /* Görselin yüksekliği tam olarak carousel alanına uyacak */
}

/* Overlay eklemek için */
.carousel-item::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Transparan siyah overlay */
  z-index: 1; /* Overlay metnin arkasında kalacak */
}

/* Carousel içeriğini hem yatay hem dikey ortalama */
.carousel-caption {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%; /* İçeriğin tam slayt boyunca ortalanması için */
  text-align: center;
  z-index: 2; /* İçerik overlay'in üstünde görünmeli */
}

/* Carousel'deki metinler için */
.carousel-content {
  color: white;
  margin-top: 80px;
}

/* Başlık için */
.carousel-content h1 {
  font-size: 3rem;
  font-weight: bold;
}

/* Alt yazı */
.carousel-content p {
  font-size: 1.25rem;
  margin-bottom: 20px;
}

/* Buton */
.consultation-btn {
  background-color: #f0ad4e;
  border: none;
  font-size: 1.1rem;
  padding: 10px 20px;
  color: white;
}

.consultation-btn:hover {
  background-color: #ec971f;
}

/* Yanlardaki okları gizlemek için */
.carousel-control-prev, .carousel-control-next {
  display: none;
}

.hero-section {
  position: relative;
  height: 100vh;
  overflow: hidden;
}

.carousel,
.carousel-inner,
.carousel-item {
  height: 100%;
}

.carousel-item img {
  height: 100%;
  object-fit: cover;
  object-position: center;
  filter: brightness(0.8);
}

.carousel-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(135deg, rgba(13, 31, 33, 0.9), rgba(13, 31, 33, 0.7));
  z-index: 1;
}

.carousel-caption {
  top: 50%;
  transform: translateY(-50%);
  bottom: auto;
  text-align: left;
  z-index: 2;
}

.carousel-content {
  max-width: 800px;
  position: relative;
}

.welcome-text {
  display: inline-block;
  font-size: 1.2rem;
  font-weight: 500;
  color: #d4af37;
  background: rgba(212, 175, 55, 0.1);
  padding: 8px 20px;
  border-radius: 30px;
  margin-bottom: 20px;
  border: 1px solid rgba(212, 175, 55, 0.3);
  backdrop-filter: blur(5px);
}

.main-title {
  font-size: 4rem;
  font-weight: 800;
  line-height: 1.2;
  margin-bottom: 30px;
  color: #fff;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
}

.main-title .highlight {
  display: block;
  color: #d4af37;
  margin-top: 10px;
}

.subtitle {
  font-size: 1.5rem;
  font-weight: 300;
  color: rgba(255, 255, 255, 0.9);
  margin-bottom: 40px;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
  max-width: 600px;
}

.button-group {
  display: flex;
  gap: 20px;
}

.contact-btn,
.about-btn {
  padding: 15px 30px;
  font-size: 1.1rem;
  font-weight: 600;
  border-radius: 50px;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  gap: 10px;
}

.contact-btn {
  background: linear-gradient(45deg, #d4af37, #f8d77f) !important;
  border: none !important;
  box-shadow: 0 4px 15px rgba(212, 175, 55, 0.3);
}

.contact-btn:hover {
  transform: translateY(-2px);
  box-shadow: 0 6px 20px rgba(212, 175, 55, 0.4);
  background: linear-gradient(45deg, #f8d77f, #d4af37) !important;
}

.about-btn {
  border: 2px solid rgba(255, 255, 255, 0.3) !important;
  backdrop-filter: blur(5px);
}

.about-btn:hover {
  background: rgba(255, 255, 255, 0.1) !important;
  border-color: #fff !important;
  transform: translateY(-2px);
}

.icon {
  transition: all 0.3s ease;
}

.about-btn:hover .icon {
  transform: translateX(5px);
}

/* Dekoratif Şekiller */
.hero-shapes {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  pointer-events: none;
}

.shape {
  position: absolute;
  background: rgba(212, 175, 55, 0.1);
  backdrop-filter: blur(5px);
  border: 1px solid rgba(212, 175, 55, 0.2);
  border-radius: 50%;
}

.shape-1 {
  width: 300px;
  height: 300px;
  top: -150px;
  right: -150px;
  animation: float 8s ease-in-out infinite;
}

.shape-2 {
  width: 200px;
  height: 200px;
  bottom: -100px;
  left: -100px;
  animation: float 6s ease-in-out infinite reverse;
}

.shape-3 {
  width: 150px;
  height: 150px;
  top: 50%;
  right: 10%;
  animation: float 10s ease-in-out infinite;
}

@keyframes float {
  0%, 100% {
    transform: translate(0, 0) rotate(0deg);
  }
  25% {
    transform: translate(10px, -10px) rotate(5deg);
  }
  50% {
    transform: translate(0, -20px) rotate(0deg);
  }
  75% {
    transform: translate(-10px, -10px) rotate(-5deg);
  }
}

/* Responsive Tasarım */
@media (max-width: 992px) {
  .main-title {
    font-size: 3.5rem;
  }

  .subtitle {
    font-size: 1.3rem;
  }

  .button-group {
    flex-direction: column;
  }

  .contact-btn,
  .about-btn {
    width: 100%;
    justify-content: center;
  }
}

@media (max-width: 768px) {
  .main-title {
    font-size: 2.8rem;
  }

  .subtitle {
    font-size: 1.2rem;
  }

  .welcome-text {
    font-size: 1rem;
  }

  .shape-1 {
    width: 200px;
    height: 200px;
  }

  .shape-2 {
    width: 150px;
    height: 150px;
  }

  .shape-3 {
    width: 100px;
    height: 100px;
  }
}

@media (max-width: 576px) {
  .main-title {
    font-size: 2.2rem;
  }

  .subtitle {
    font-size: 1.1rem;
  }

  .contact-btn,
  .about-btn {
    padding: 12px 25px;
    font-size: 1rem;
  }
}

/* Animasyonlar */
@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translateY(-30px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(30px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Animasyon sınıfları */
.animate-welcome {
  opacity: 0;
  animation: fadeInDown 0.8s ease forwards;
  animation-delay: 0.2s;
}

.animate-title {
  opacity: 0;
  animation: fadeInUp 0.8s ease forwards;
  animation-delay: 0.4s;
}

.animate-subtitle {
  opacity: 0;
  animation: fadeInUp 0.8s ease forwards;
  animation-delay: 0.6s;
}

.animate-buttons {
  opacity: 0;
  animation: fadeInUp 0.8s ease forwards;
  animation-delay: 0.8s;
}
