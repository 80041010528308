/* Footer Section */
.footer-section {
    background-color: #0d2118 !important;
    color: #ffffff;
    padding: 60px 0; /* Yüksekliği artırmak için padding artırıldı */
  }
  
  /* Footer Başlıklar */
  .footer-title {
    font-size: 1.25rem;
    font-weight: bold;
    margin-bottom: 1.5rem;
    color: #ffffff;
  }
  
  /* Footer Linkler */
  .footer-link {
    color: #b8b8b8;
    text-decoration: none;
    transition: color 0.3s ease;
  }
  
  .footer-link:hover {
    color: #f0ad4e;
    text-decoration: none;
  }
  
  /* About Us İkonlar */
  .fas {
    margin-right: 10px;
    color: #f0ad4e;
  }
  
  /* Son Haberler Resimleri */
  .footer-news-img {
    width: 60px;
    height: 60px;
    object-fit: cover;
  }
  
  /* Son Haberler Tarih Stili */
  .news-date {
    font-size: 0.85rem;
    color: #b8b8b8;
    margin-bottom: 5px;
  }
  
  /* Footer Alt Bilgi */
  .footer-section p {
    margin-bottom: 0;
    color: #b8b8b8;
  }
  
  /* Responsive İçin */
  @media (max-width: 768px) {
    .footer-title {
      text-align: center;
    }
    .footer-section ul {
      text-align: center;
    }
  }
  