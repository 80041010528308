.blog-section {
  background-color: #f8f9fa;
  position: relative;
  padding: 80px 0;
}

.section-header {
  margin-bottom: 50px;
}

.section-title {
  color: #333;
  font-size: 2.5rem;
  font-weight: 700;
  margin-bottom: 20px;
  text-transform: uppercase;
}

.section-divider {
  width: 60px;
  height: 3px;
  background-color: #d4af37;
  margin: 0 auto;
}

/* Blog Slider Styles */
.blog-slider {
  margin: 0 -10px;
  padding: 20px 0 60px;
}

.blog-slider .slick-list {
  margin: 0 -15px;
}

.blog-slider .slick-slide {
  padding: 15px;
}

.blog-item {
  background: #fff;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 2px 15px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
  cursor: pointer;
  height: 100%;
  margin: 10px;
}

.blog-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.15);
}

.blog-image {
  position: relative;
  height: 200px;
  overflow: hidden;
}

.blog-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease;
}

.blog-item:hover .blog-image img {
  transform: scale(1.1);
}

.blog-content {
  padding: 20px;
}

.blog-date {
  color: #666;
  font-size: 0.9rem;
  margin-bottom: 10px;
}

.blog-title {
  color: #333;
  font-size: 1.25rem;
  margin-bottom: 15px;
  font-weight: 600;
  line-height: 1.4;
}

.blog-excerpt {
  color: #666;
  font-size: 0.95rem;
  line-height: 1.6;
  margin-bottom: 15px;
}

.blog-read-more {
  color: #d4af37;
  font-weight: 600;
  font-size: 0.95rem;
  transition: color 0.3s ease;
  text-decoration: none;
  display: inline-flex;
  align-items: center;
}

.blog-item:hover .blog-read-more {
  color: #b38f1d;
}

/* Slider Navigation */
.slick-prev,
.slick-next {
  width: 40px;
  height: 40px;
  background: #fff;
  border-radius: 50%;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 1;
  transition: all 0.3s ease;
}

.slick-prev:hover,
.slick-next:hover {
  background: #d4af37;
}

.slick-prev:before,
.slick-next:before {
  color: #d4af37;
  transition: color 0.3s ease;
}

.slick-prev:hover:before,
.slick-next:hover:before {
  color: #fff;
}

.slick-prev {
  left: -20px;
}

.slick-next {
  right: -20px;
}

/* Slider Dots */
.slick-dots {
  bottom: -40px;
}

.slick-dots li button:before {
  font-size: 12px;
  color: #d4af37;
  opacity: 0.3;
}

.slick-dots li.slick-active button:before {
  opacity: 1;
  color: #d4af37;
}

/* Modal Styles */
.blog-modal .modal-content {
  border: none;
  border-radius: 10px;
  overflow: hidden;
}

.blog-modal .modal-header {
  border-bottom: 2px solid #f0f0f0;
  padding: 20px 30px;
}

.blog-modal .modal-title {
  color: #333;
  font-weight: 600;
}

.blog-modal .modal-body {
  padding: 30px;
}

.blog-modal-image {
  border-radius: 8px;
  overflow: hidden;
  max-height: 400px;
}

.blog-modal-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.blog-modal-date {
  color: #666;
  font-size: 0.95rem;
}

.blog-modal-content {
  color: #444;
  line-height: 1.8;
  font-size: 1.1rem;
}

.blog-modal-content img {
  max-width: 100%;
  height: auto;
  margin: 15px 0;
  border-radius: 5px;
}

/* Responsive */
@media (max-width: 992px) {
  .section-title {
    font-size: 2rem;
  }

  .blog-item {
    margin: 5px;
  }

  .slick-prev {
    left: -15px;
  }

  .slick-next {
    right: -15px;
  }
}

@media (max-width: 768px) {
  .blog-section {
    padding: 60px 0;
  }

  .section-title {
    font-size: 1.8rem;
  }

  .blog-image {
    height: 180px;
  }
}

@media (max-width: 576px) {
  .blog-item {
    margin: 0;
  }

  .blog-content {
    padding: 15px;
  }

  .blog-title {
    font-size: 1.1rem;
  }

  .blog-excerpt {
    font-size: 0.9rem;
  }
} 