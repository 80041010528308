.page-wrapper {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

/* Hero Section */
.contact-hero {
  position: relative;
  height: 300px;
  background: linear-gradient(rgba(18, 38, 32, 0.9), rgba(18, 38, 32, 0.9)), url('../assets/banners/terazi.png');
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: #fff;
  margin-bottom: 4rem;
  position: relative;
  overflow: hidden;
}

.contact-hero::before {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100px;
  background: linear-gradient(to top, #fff, transparent);
}

.hero-content {
  position: relative;
  z-index: 2;
  text-align: center;
  max-width: 800px;
  margin: 0 auto;
  padding: 2rem;
}

.hero-title {
  font-size: 3rem;
  font-weight: 700;
  margin-bottom: 1rem;
  text-transform: uppercase;
  letter-spacing: 3px;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
  animation: fadeInDown 1s ease;
}

.hero-subtitle {
  font-size: 1.2rem;
  font-weight: 300;
  margin-top: 1rem;
  color: rgba(255, 255, 255, 0.9);
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
  animation: fadeInUp 1s ease;
}

.hero-divider {
  width: 80px;
  height: 4px;
  background-color: #E4B649;
  margin: 1rem auto;
  animation: widthGrow 1.5s ease;
}

/* Contact Info Section */
.contact-info-section {
  padding: 4rem 0;
  background: linear-gradient(135deg, #f8f9fa 0%, #fff 100%);
  position: relative;
}

.contact-card {
  background: #fff;
  padding: 2rem;
  border-radius: 10px;
  text-align: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
  position: relative;
  overflow: hidden;
  height: 100%;
}

.contact-card:hover {
  transform: translateY(-5px);
}

.icon-wrapper {
  width: 70px;
  height: 70px;
  background: #122620;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto 1.5rem;
}

.contact-icon {
  font-size: 1.8rem;
  color: #E4B649;
}

.contact-card h3 {
  color: #122620;
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

.contact-card p {
  color: #666;
  margin: 0;
}

.contact-card a {
  color: #666;
  text-decoration: none;
  transition: color 0.3s ease;
}

.contact-card a:hover {
  color: #E4B649;
}

.card-decoration {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 4px;
  background: #E4B649;
  transform: scaleX(0);
  transition: transform 0.3s ease;
  transform-origin: left;
}

.contact-card:hover .card-decoration {
  transform: scaleX(1);
}

/* Map Section */
.map-section {
  position: relative;
  height: 600px;
  overflow: hidden;
  margin-top: 4rem;
  margin-bottom: 4rem;
}

.map-container {
  height: 100%;
  width: 100%;
  position: relative;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 2px 15px rgba(0, 0, 0, 0.1);
}

.map-container::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100px;
  background: linear-gradient(to bottom, #fff, transparent);
  z-index: 1;
  pointer-events: none;
}

.map-container iframe {
  width: 100%;
  height: 600px;
  border: none;
}

/* Animations */
@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translateY(-30px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(30px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes widthGrow {
  from {
    width: 0;
  }
  to {
    width: 100px;
  }
}

/* Responsive Styles */
@media (max-width: 992px) {
  .hero-title {
    font-size: 3.5rem;
  }

  .hero-subtitle {
    font-size: 1.5rem;
  }

  .contact-card {
    margin-bottom: 30px;
  }
}

@media (max-width: 768px) {
  .contact-hero {
    height: 250px;
  }

  .hero-title {
    font-size: 2.5rem;
  }

  .hero-subtitle {
    font-size: 1.3rem;
  }

  .contact-info-section {
    padding: 60px 0;
  }

  .map-section {
    height: 450px;
  }
}

@media (max-width: 576px) {
  .hero-title {
    font-size: 2.2rem;
  }

  .contact-card {
    padding: 30px 20px;
  }

  .map-section {
    height: 400px;
  }
}

.contact-page {
  background-color: #f8f9fa;
}

.contact-info-box {
  background: white;
  border-radius: 10px;
  box-shadow: 0 2px 15px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
  height: 100%;
}

.contact-info-box:hover {
  transform: translateY(-5px);
}

.contact-form-box {
  background: white;
  border-radius: 10px;
  box-shadow: 0 2px 15px rgba(0, 0, 0, 0.1);
}

/* Form styling */
.form-control {
  border-radius: 5px;
  border: 1px solid #ced4da;
  padding: 0.75rem;
}

.form-control:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  border-color: #80bdff;
}

.btn-primary {
  padding: 0.75rem 2rem;
  border-radius: 5px;
  font-weight: 600;
  transition: all 0.3s ease;
}

.btn-primary:hover {
  transform: translateY(-2px);
  box-shadow: 0 2px 10px rgba(0, 123, 255, 0.3);
} 